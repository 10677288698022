import type QuickView from '@js/components/modals/QuickView/QuickView.svelte'
import { createClassComponent } from 'svelte/legacy'

let loadedQuickView: ReturnType<typeof QuickView> | undefined

/**
 * Opens the quick view modal
 * @param productID - The ID of the product to be opened in the quick view modal
 * @param wrapperElement - The element that wraps the product cards. This is used to determine the connected products
 * @param mode - The mode of the quick view modal. Can be either 'buy' or 'default'. When 'buy' is specified, the modal will be opened in 'buy' mode
 * @param productUrl - The URL of the tile collection
 */
export const openQuickView = (
  productID: string,
  wrapperElement: HTMLElement,
  mode: 'buy' | 'default' = 'default',
  productUrl?: string,
) => {
  const connectedProducts: QuickViewConnectedProducts = new Map()
  let currentCardIndex = 0
  const closeProducts = wrapperElement.querySelectorAll<HTMLElement>('.product-card, .product-card-mini__product-link')
  for (const [index, closeProductCard] of closeProducts.entries()) {
    const cardProductID = closeProductCard.dataset.productId
    if (cardProductID) {
      connectedProducts.set(index, {
        id: cardProductID,
        url: closeProductCard.dataset.url ?? null,
      })
    }

    if (cardProductID === productID) {
      currentCardIndex = index
    }
  }

  if (loadedQuickView) {
    loadedQuickView.$set({
      show: true,
      productID,
      productUrl,
      connectedProducts,
      currentCardIndex,
      mode,
    })
  } else {
    // eslint-disable-next-line promise/prefer-await-to-then
    import('@js/components/modals/QuickView/QuickView.svelte').then((imported) => {
      loadedQuickView = createClassComponent({
        component: imported.default,
        target: document.body,
        props: {
          show: true,
          productID,
          productUrl,
          connectedProducts,
          currentCardIndex,
          mode,
        },
      })
      loadedQuickView.$set({
        onHideExport: () => {
          loadedQuickView?.$set({
            show: false,
            productID: undefined,
            url: undefined,
            connectedProducts: new Map(),
            mode: undefined,
            currentCardIndex: undefined,
          })
        },
      })
    })
  }
}

export type QuickViewConnectedProducts = Map<number, { id: string; url: null | string }>
