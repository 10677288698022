// eslint-disable-next-line canonical/filename-match-regex
import Cookies from 'js-cookie'

export const initPageHeaderNotification = () => {
  const notification = document.querySelector('.page-header-notification')
  const notificationCloseButton = notification?.querySelector('.page-header-notification__close')
  if (notification && notificationCloseButton) {
    if (Cookies.get('san-notification-hidden') === 'true') {
      notification.classList.add('d-none')
    }

    notificationCloseButton.addEventListener(
      'click',
      () => {
        notification.classList.add('d-none')
        Cookies.set('san-notification-hidden', 'true', { expires: 30, sameSite: 'Lax', path: '/', secure: true })
      },
      { passive: true },
    )
  }
}
