import { notiStore } from '@js/components/Notifications/notification-store'
import Cookies from 'js-cookie'
import { writable } from 'svelte/store'
import wretch from 'wretch'
import QueryStringAddon from 'wretch/addons/queryString'
import { dedupe, retry } from 'wretch/middlewares'

export const getFavourites = () => {
  const faves = Cookies.get('favorites_products')

  if (faves) {
    const favesArray = faves.split(',')

    return new Set<string>(favesArray)
  }

  return new Set<string>()
}

export const favouritesStore = writable<Set<string>>(getFavourites())

export const setHeaderFavouritesCount = () => {
  const favesCountElement = document.querySelector('.big-icon-link--favourites .big-icon-link__count')
  const favesMobileCountElement = document.querySelector('.mobile-navbar-usermenu .nav-link--favourites sup')
  if (!favesCountElement || !favesMobileCountElement) {
    return
  }

  favouritesStore.subscribe((favourites) => {
    if (favourites.size > 0) {
      favesCountElement.classList.add('show')
    } else {
      favesCountElement.classList.remove('show')
    }

    favesMobileCountElement.textContent = String(favourites.size)
    favesCountElement.textContent = String(favourites.size)
  })
}

// Возвращает результат добавления: в избранном (true) / удалено из избранного (false). Null - запрос не удался.
export const toggleFavourite = async (productID: string) => {
  if (import.meta.env.DEV) {
    notiStore.push('success', { timeout: 3_000, props: { content: 'DEV: как бы добавлено в избранное' } })
    return true
  }

  const localWretch = wretch('/ajax/get_data.php')
    .addon(QueryStringAddon)
    .middlewares([
      retry({
        maxAttempts: 3,
      }),
      dedupe(),
    ])
  const response = await localWretch
    .post({
      object: 'user-favorites',
      mode: 'toggle_favorite',
      uid: window.santehnica_data?.user?.id,
      pid: productID,
      session_id: window.santehnica_data?.session,
    } satisfies ToggleFavouriteRequest)
    .json<ToggleFavouriteResponse>()
    .catch(() => {
      notiStore.push('error', { timeout: false, props: { content: 'Не удалось изменить избранное' } })
      return null
    })

  favouritesStore.set(getFavourites())

  return response ? response.data : null
}

type ToggleFavouriteRequest = {
  pid: string
  object: 'user-favorites'
  mode: 'toggle_favorite'
  uid?: string | undefined
  session_id?: string | undefined
}

type ToggleFavouriteResponse = {
  success: boolean
  data: boolean // added or not
  mode: 'add' | 'remove' | 'toggle'
  count: number
}
