<script lang="ts">
  import { sprites } from '@js/includes/_generatedVariables.ts'

  type Props = {
    title?: string
    ariaHidden?: boolean
    iconID: string
    width?: number | string
    height?: number | string
    spriteName?: string
    class?: string
  }

  let {
    title = '',
    ariaHidden = title === '',
    iconID,
    width = 24,
    height = $bindable(24),
    spriteName = 'svgsprite2',
    class: className = '',
  }: Props = $props()

  height = typeof width === 'string' ? Number.parseInt(width, 10) : width

  let iconPath = $state(sprites[spriteName] + '#' + iconID)
  if (spriteName === 'product-type-icons-sprite') {
    iconPath = `${import.meta.env.VITE_TEMPLATE_DIR}media/icons/product-type-icons-sprite/${iconID}.svg#svg`
  }
</script>

{#if spriteName === 'product-type-icons-sprite' && iconID.startsWith('colors--')}
  <img
    src={iconPath}
    alt={title}
    {width}
    {height}
    class={className === '' ? null : className}
    aria-hidden={ariaHidden === false ? null : true}
    loading="lazy"
  />
{:else}
  <svg
    {width}
    {height}
    aria-hidden={ariaHidden === false ? null : true}
    role={ariaHidden ? null : 'img'}
    class={className === '' ? null : className}
  >
    {#if title}
      <title>{title}</title>
    {/if}
    <use xlink:href={iconPath}></use>
  </svg>
{/if}

<svelte:options
  customElement={{
    tag: 'svg-icon',
    shadow: 'none',
    props: {
      class: { attribute: 'icon-class' },
      ariaHidden: { attribute: 'aria-hidden' },
    },
  }}
/>
